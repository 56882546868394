import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const StreamLinks = () => (
  <div className="m-5">
    <div className="flex justify-center content-center">
      <a
        className="mr-4"
        href="https://music.apple.com/fr/artist/golden-dice/id1515921284"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../images/Apple-Music.svg"
          width={50}
          quality={95}
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Listen to us on Apple Music"
        />
      </a>
      <a
        href="https://open.spotify.com/artist/4rE5PsuzSrscUh7tNdJBDm?si=-VL69mm_SFOwv1zgR8KE3Q&nd=1"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../images/spotify.svg"
          width={50}
          quality={95}
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Find us on Spotify"
        />
      </a>
    </div>
  </div>
)
export default StreamLinks
