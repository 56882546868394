import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SocialLinks = () => (
  <div className="m-5">
    <div className="flex justify-center content-center">
      <a
        className="mr-4"
        href="https://www.facebook.com/goldendicemusic"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../images/facebook.svg"
          width={50}
          quality={95}
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Find us on Facebook"
        />
      </a>
      <a
        href="https://www.instagram.com/goldendicemusic"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../images/instagram.svg"
          width={50}
          quality={95}
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Find us on Instagram"
        />
      </a>
    </div>
  </div>
)
export default SocialLinks
