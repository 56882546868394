import React, { useEffect } from "react"
import Parallax from "parallax-js"
import { StaticImage } from "gatsby-plugin-image"

const ParallaxComponent = () => {
  useEffect(() => {
    var parallaxInstance = new Parallax(document.getElementById("scene"), {
      relativeInput: true,
      hoverOnly: true,
      inputElement: document.getElementById("scene-holder"),
      limitX: false,
      limitY: false,
    })

    parallaxInstance.enable()

    return () => {
      parallaxInstance.disable()
      // sceneEl.current.onmouseleave = null;
    }
  }, [])
  return (
    <div className="pt-10 bg-black">
      <div id="scene">
        <div data-depth="0.9">
          <h1 className="flex justify-center content-center">
            <StaticImage
              src="../images/golden-dice-music-logo-gold-tall.png"
              width={300}
              quality={95}
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Logo Golden Dice Music"
            />
          </h1>
        </div>
      </div>
    </div>
  )
}
export default ParallaxComponent
