import * as React from "react"

import ParallaxIMG from "../components/ParallaxIMG"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/SocialLinks"
import StreamLinks from "../components/StreamLinks"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Site coming soon, please come back later"
      description="Golden Dice Music site is under construction, please come back later to see some exciting content about us."
    />
    <ParallaxIMG />
    <div>
      <p className="text-white text-center text-xl font-bold">
        While we work hard to provide you the best experience we can,
        <br />
        You can find us on Social Media and Stream Platforms!
      </p>
    </div>
    <SocialLinks />
    <StreamLinks />
  </Layout>
)

export default IndexPage
